.scrollable-content {
  /* height: 500px; */
  overflow-x: hidden;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* .scrollable-content::-webkit-scrollbar {
  display: none;
} */
.loader-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.no-content-message {
  height: 100%;
  color: #a2a3b1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-content-text {
  font-size: 1rem;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #9eabbc;
  text-align: center;
}
.custom-notification {
  left: 0;
}
.deactivated {
  background-color: #eeefeb;
}
.cursor-pointer {
  cursor: pointer;
}

.custom-date-picker .react-datepicker-wrapper {
  width: 100%;
}

/* ========== Custom Tabs Section Start ========== */
.nav-tabs > .nav-item > .nav-link {
  white-space: nowrap;
  color: var(--primary-text-color);
  cursor: pointer;
  font-size: 0.875rem !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--theme-color);
  background-color: #def1ff;
  border-color: #dee2e6 #dee2e6 #f8f9fe;
}
.tab-content {
  padding-top: 1rem;
}
/* ========== Custom Tabs Section End ========== */

/* ========== Custom Label Start ========== */
.control-label-top {
  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary-text-color);
}
.control-label {
  font-size: 0.88rem;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--primary-text-color);
  vertical-align: middle;
  margin-top: 5px;
  padding-right: 0px;
}
.label-mandatory {
  color: #ff0000;
}
/* ========== Custom Label Start ========== */

/* ========== Custom Error Message Start ========== */
.has-danger .text-help {
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--danger);
}
/* ========== Custom Error Message End ========== */

/* ========== Custom Checkbox Start ========== */
.custom-container-checkbox {
  display: block;
  position: relative;
  padding-left: 1.875rem;
  margin-bottom: 0.2rem !important;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom-container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: solid 1px #d9d9d9;
  background-color: #ffffff;
}
.custom-container-checkbox .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  border-radius: 2px;
  -webkit-transform: rotate(38deg);
  -ms-transform: rotate(38deg);
  transform: rotate(38deg);
  content: "";
  position: absolute;
  display: none;
}
.custom-container-checkbox:hover input ~ .checkmark {
  background-color: #f6f7fb;
}
.custom-container-checkbox input:checked ~ .checkmark {
  background-color: var(--theme-color);
  border: solid 0px #d9d9d9;
}
.custom-container-checkbox input:checked ~ .checkmark:after {
  display: block;
}
/* ========== Custom Checkbox End ========== */

/* ========== Custom Radio Start ========== */
.custom-container-radio {
  display: block;
  position: relative;
  padding-left: 1.875rem;
  margin-bottom: 0.2rem !important;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom-container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.radiomark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: solid 1px #dce2e9;
  background-color: #ffffff;
  border-radius: 50%;
}
.custom-container-radio .radiomark:after {
  top: 5.7px;
  left: 5.7px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #ffffff;
  position: absolute;
  display: none;
  content: "";
}
.custom-container-radio:hover input ~ .radiomark {
  border: solid 1px #dce2e9;
  background-color: #f6f7fb;
}
.custom-container-radio input:checked ~ .radiomark {
  background-color: var(--theme-color);
}
.custom-container-radio input:checked ~ .radiomark:after {
  display: block;
}
/* ========== Custom Radio End ========== */

/* --- Scrolling table elements with fix thead --- */
.activity-list table thead,
.activity-list table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.activity-list table tbody {
  display: block;
  max-height: 400px;
  overflow-y: auto;
}
/* --- Scrolling table elements with fix thead --- */

.ismanager {
  /* border: 1px solid #e9ecef; */
  border: 1px solid var(--light);
  border-radius: 1.563rem;
  padding: 0 0.5rem;
}

.header-buttons {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.header-buttons > .buttons-option:first-child {
  margin-left: 0 !important;
}

.header-buttons > .buttons-option {
  margin: 0 0 0 0.625rem;
}

.event-modal .modal-content .modal-header .close {
  padding: 0;
  margin: 0;
}

.input-type-div {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #18203a;
  color: var(--primary-text-color);
  background-color: #ece8e8;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.input-type-div .text-display {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.uploaded-image-section {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.625rem 0.75rem;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  text-align: center;
}
/* Color Picker */
.swatch-picker {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.313rem;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--theme-color);
  color: var(--theme-color);
}
.picker-container {
  position: absolute;
  top: 0;
  left: 4rem;
  z-index: 999;
}
/* Color Picker */

.react-confirm-alert-overlay {
  z-index: 1055 !important;
}

.common-data-table {
  min-height: 220px !important;
}

@media screen and (max-width: 320px) {
  .custom-notification .notification-container--top-right .notification {
    width: 280px !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}
